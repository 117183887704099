import { getNinjaConfig } from './config/ninja';
import { getRegionConfig } from './config/region';
import { TIMESTAMP_PROPERTY, Trackers } from './const';
import { initCookieStorage } from './cookies';
import { checkParam, currentTracker, getPluginList, getSessionParams } from './core';
import { trackError } from './core/utils';
import { init as initLaquesis, setupNativeWindow } from './trackers/laquesis';
import { eucex, getCurrentPath } from './utils';

let originalDataLayerPush;

export function initNinja() {
  try {
    let foundTrackPage;
    const ninjaConfig = getNinjaConfig();

    if (getRegionConfig().custom[ninjaConfig.siteUrl]) {
      // Setup dataLayer
      ninjaConfig.dataLayer = ninjaConfig.dataLayer || [];
      ninjaConfig.dataLayerDelayed = ninjaConfig.dataLayerDelayed || [];

      // Overwrite function push
      if (!originalDataLayerPush) {
        originalDataLayerPush = ninjaConfig.dataLayer.push;
      }
      ninjaConfig.dataLayer.push = function (params = {}) {
        if (params) {
          const withTime = {
            ...params,
          };
          // delayed tracking has this value already set
          if (!(TIMESTAMP_PROPERTY in withTime)) {
            withTime[TIMESTAMP_PROPERTY] = Date.now();
          }

          let result = originalDataLayerPush.apply(ninjaConfig.dataLayer, [withTime]);

          checkParam();

          return result;
        }
        return false;
      };

      // Set internal queue
      if (!window.trackingQueue) {
        window.trackingQueue = [];
      }
      window.trackingQueue.push = function (func) {
        if (typeof func === 'function') {
          func();
          return 0;
        }
        return -1;
      };

      while (window.trackingQueue.length > 0) {
        window.trackingQueue.push(window.trackingQueue.shift());
      }

      // Cookies are read only on track action. Laquesis needs sessionLong to init
      // Force cookie reading if no default event or no existing dataLayer events have been processed
      if (!ninjaConfig.currentSession) {
        getSessionParams(true);
      }
      // Init laquesis if not in a webview

      if (ninjaConfig.isNative) {
        setupNativeWindow();
      } else if (getPluginList().includes(Trackers.Laquesis)) {
        setTimeout(initLaquesis, 0);
      }

      // Call async check
      for (const evt of ninjaConfig.dataLayer) {
        if (evt.trackPage) {
          foundTrackPage = true;
        }
      }
      if (!foundTrackPage && !ninjaConfig.disableDefaultTrackPage) {
        if (!ninjaConfig.unitTest) {
          if (window.location.pathname) {
            // Track the current path name if there is no trackPage when the library is called
            ninjaConfig.dataLayer.push({ trackPage: eucex(getCurrentPath()) });
          } else {
            ninjaConfig.dataLayer.push({ trackPage: 'home' });
          }
        }
      } else {
        checkParam();
      }
    }
  } catch (error) {
    if (typeof trackError === 'function') {
      trackError('JAVASCRIPT_ERROR', currentTracker, '', error);
    }
  }
}

/**
 * Hydra is always working and 3rd party trackers check for consent on each tracking call.
 */
export function startNinja() {
  initCookieStorage(() => {
    setTimeout(() => {
      initNinja();
    }, 100);
  });
}
