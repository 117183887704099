import { CategoryCodes, isConsentGiven } from '@olxeu-eprivacy-storage/js';
import { getNinjaConfig } from './config/ninja';
import { Trackers } from './const';
import { isEprivacyStorageRequired } from './cookies/isEprivacyStorageRequired';

const REQUIRED_CATEGORIES_FOR_3RD_PARTY = [CategoryCodes.C0002, CategoryCodes.C0003];

/**
 * Checks for consent for specific tracker
 * @param {Tracker} tracker
 */
export function hasRequiredConsentFor(tracker) {
  // when in native mode no tracking is allowed. The tracking is done by the native app
  if (getNinjaConfig().isNative) {
    return false;
  }
  // Hydra is always allowed
  if (tracker === Trackers.Hydra || !isEprivacyStorageRequired()) {
    return true;
  }
  // 3rd party trackers need C0002 and C0003
  return REQUIRED_CATEGORIES_FOR_3RD_PARTY.every(isConsentGiven);
}
