import { getNinjaConfig } from '../config/ninja';
import { TIMESTAMP_PROPERTY } from '../const';

export const sortProp = 'trackOrder';

/**
 * @param {*} o
 * @returns boolean
 */
function isNumber(o) {
  return typeof o === 'number';
}

/**
 * Track page delayed
 * @param {String} name page name
 * @param {Record<string, any>} params
 */
export function trackPageDelayed(name, params) {
  const trackParams = Object.assign({}, params || {}, { trackPage: name });

  trackParams[TIMESTAMP_PROPERTY] = Date.now();

  getNinjaConfig().dataLayerDelayed.push(trackParams);
}

/**
 * Track ninja event delay
 * @param {String | String[]} eventName Event to track
 * @param {Record<string, any>} params
 */
export function trackEventDelayed(name, params) {
  if (name && getNinjaConfig().dataLayerDelayed) {
    let trackParams = Object.assign({}, params || {}, {
      trackEvent: Array.isArray(name) ? name : [name],
    });
    trackParams[TIMESTAMP_PROPERTY] = Date.now();

    getNinjaConfig().dataLayerDelayed.push(trackParams);
  }
}

/**
 * @param {string} pageName
 * @param {Record<string, unknown>} eventData
 * @param {number} order
 */
export function trackLinkEventDelayed(pageName, eventData, order) {
  console.log(pageName, eventData, order);
  // TODO ?
}

/**
 * Flushes all delayed events
 */
export function flushDelayed() {
  checkParamDelayed();
}

/**
 * Sort delayed queue:
 * - asc by `sortOrder`
 * - items without `sortOrder` are put at the end in the order of adding
 * @param {Record<string, any>} a
 * @param {Record<string, any>} b
 * @returns number
 */
export function sortOrderComparator(a, b) {
  // if both have order, compare it
  if (isNumber(a[sortProp]) && isNumber(b[sortProp])) {
    return a[sortProp] - b[sortProp];
  }

  // if only left has order, make sure it's always on top
  if (isNumber(a[sortProp])) {
    return -Infinity;
  }

  // no index = bottom
  return Infinity;
}

// process the delayed queue
export function checkParamDelayed() {
  const sorted = getNinjaConfig().dataLayerDelayed.sort(sortOrderComparator);

  // cleanup, no need to keep events - they will be in the immediate queue in the correct order
  // it's safe to do this because there's no custom push() for this queue
  getNinjaConfig().dataLayerDelayed = [];

  for (let data of sorted) {
    if (typeof data === 'object') {
      // this property is not to be tracked
      delete data[sortProp];
      // put the event in the immediate queue to be processed as usual
      getNinjaConfig().dataLayer.push(data);
    }
  }
}
